<template>
  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m1">
        <div class="mini_fram">
          <leisure-game-ifram-view>
<!--            <iframe id="bet365-gamefram"  scrolling="no" frameborder="0"
                    src="https://vet3.net/video?q=high&player=1&autostart=true&title=Premiership"></iframe>-->

            <iframe id="bet365-gamefram"  scrolling="no" frameborder="0"
                    src="https://b1.nust365.com/ios/?vn=1"></iframe>
          </leisure-game-ifram-view>
          <leisure-bet365-game-rsult-comp :kind="kind"></leisure-bet365-game-rsult-comp>
        </div>
      </div>
      <div class="m2">
        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
                  ref="ltimer"
                  :leisure-game-info="leisureGameInfo"
                  @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange"><i class="fa fa-star"></i>프리미어</span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">
            <div class="b_s_match_box">
              <div class="team_name">
                <div class="home"><span>{{spGame.homeTeamName}}</span></div>
                <div class="vs"><span>VS</span></div>
                <div class="away"><span>{{spGame.awayTeamName}}</span></div>
              </div>
              <div class="mg">
                <div class="mg_btn b3" :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_1x2 && selectedInfo.selectedWay === 1}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_1x2, spGame.id,1, spGame.homeTeamOdds, spConfig, '승')">
                  <div class="rs">
                    <span class="n text_color01">승</span>
                    <span class="b">{{ spGame.homeTeamOdds}}</span>
                  </div>
                </div>
                <div class="mg_btn b3" :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_1x2 && selectedInfo.selectedWay === 2}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_1x2, spGame.id,2,  spGame.drawOdds, spConfig, '무')">
                  <div class="rs">
                    <span class="n text_color02">무</span>
                    <span class="b">{{ spGame.drawOdds}}</span>
                  </div>
                </div>
                <div class="mg_btn b3" :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_1x2 && selectedInfo.selectedWay === 3}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_1x2, spGame.id,3,  spGame.awayTeamOdds, spConfig, '패')">
                  <div class="rs">
                    <span class="n text_color02">패</span>
                    <span class="b">{{ spGame.awayTeamOdds}}</span>
                  </div>
                </div>
              </div>
              <div class="mg">
                <div class="mg_btn b2" :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_OVERUNDER && selectedInfo.selectedWay === 1}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_OVERUNDER,souGame.id,1, souGame.homeTeamOdds, souConfig, '오버')">
                  <div class="rs">
                    <span class="n text_color01">오버({{ souGame.overunderVal}})</span>
                    <span class="b">{{ souGame.homeTeamOdds}}</span>
                  </div>
                </div>
                <div class="mg_btn b2" :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_OVERUNDER && selectedInfo.selectedWay === 3}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_OVERUNDER,souGame.id,3,  souGame.awayTeamOdds, souConfig, '언더')">
                  <div class="rs">
                    <span class="n text_color02">언더({{ souGame.overunderVal}})</span>
                    <span class="b">{{ souGame.awayTeamOdds}}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!--파워볼 베팅카트-->
          <leisure-bet-cart-comp
                  ref="betCart" :kind="kind"
                  :selected-info="selectedInfo">
            프리미어
          </leisure-bet-cart-comp>


        </div>
      </div>
      <div class="m3">
        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
                ref="latelyBetList"
                :kind="kind"></leisure-lately-bet-list-comp>

      </div>


    </div>
  </div>
</template>

<script>
  import SubTitle from "../../../components/SubTitle";
  import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
  import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
  import leisureConst from "../../../common/leisureConst";
  import sportsConst from "../../../common/sportsConst";
  import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
  import {leisureGameMixin} from "../../../common/mixin";
  import {getLeisureGameAttributeConfig} from "../../../network/leisureRequest";
  import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
  import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
  import LeisureGameMathBoxComp from "../../../components/leisuregame/LeisureGameMathBoxComp";
  import LeisureGameLinks from "../LeisureGameLinks";
  import LeisureGameIframView from "../LeisureGameIframView";
  import LeisureB365SoccerLinks from "../LeisureB365SoccerLinks";
  import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";
  import LeisureBet365GameRsultComp from "../../../components/leisuregame/LeisureBet365GameRsultComp";

  export default {

    name: "Bet365SoccerPremiership",
    mixins: [leisureGameMixin],
    components: {
      LeisureBet365GameRsultComp,
      LeisureB365SoccerLinks,
      LeisureGameIframView,
      LeisureGameLinks,
      LeisureGameMathBoxComp,
      LeisureGameView,
      LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle},
    data() {
      return {
        kind: leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP,
      }
    },

    methods: {
      initLeisureGameArttributeConfig() {
        getLeisureGameAttributeConfig().then(res => {
          if (res.data.success) {
            this.attrConfig = res.data.data
            //배당설정
            this.spConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_1x2)
            })

            this.souConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_BET365_SOCCER_PREMIERSHIP_OVERUNDER)
            })
          }
        })
      }
    },
    created() {
      this.initKindConfig(this.kind)
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP,leisureConst.LEISURE_COMP_BET365);
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID,this.kind);
    },
  }
</script>


<style scoped>

  @media screen and (max-width: 1024px) {
    .mini_game .m2{
      --margin-top: 180px;
    }
    .mini_game .mini_fram{
      min-height: 210px!important;
    }
  }
</style>